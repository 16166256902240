<template>
    <div class="contain-box">
        <CardBox>
            <template #tool-title>
                <div class="tab-bar">
                    <span :class="{ active: index === currentTab }" v-for="(item, index) in tab" :key="index" @click="currentTab = index">
                        {{ t(item.tabName) }}
                    </span>
                </div>
            </template>
            <template #contain>
                <div class="info-box" v-if="tab.length > 0">
                    <template v-for="(item, index) in tab[currentTab].tabData" :key="index">
                        <div class="flex-box" v-if="item.show">
                            <span class="icon">
                                <SvgIcon :name="item.icon" color="#2b32c9" :size="28" />
                            </span>
                            <div class="content">
                                <p>{{ t(item.title) }}</p>
                                {{ item.msg }}
                            </div>
                            <span class="operate" v-if="item.btn">
                                <Tooltip :content="formData.key" v-if="item.icon === 'key'">
                                    <Button class="submit-btn btn-fill" size="small" type="primary" @click="item.btnFunc(item.type)">
                                        {{ t(item.btn) }}
                                    </Button>
                                </Tooltip>
                                <Button class="submit-btn btn-fill" size="small" type="primary" @click="item.btnFunc(item.type)" v-else>
                                    {{ t(item.btn) }}
                                </Button>
                            </span>
                        </div>
                    </template>
                </div>
            </template>
        </CardBox>
        <Modal width="500px" v-model:visible="modalVisible" @close="handleClose" :mask-closable="false">
            <template #title>
                <p class="modal-title">{{ t('modifyInfo', { info: t('login.' + modalType) }) }}</p>
            </template>
            <div @keyup.enter="handleSubmit">
                <Form ref="formDom" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                    <FormItem field="phone" :validate-trigger="['blur']" v-if="modalType === 'phone'">
                        <Input
                            v-model="formData.phone"
                            spellcheck="false"
                            :placeholder="t('holder.enterHolder')"
                            @blur="formData.phone = formData.phone.trim()" />
                    </FormItem>
                    <p v-if="modalType === 'phone'" style="font-size: 14px; color: #999">{{ t('tip.modifyPhoneTip') }}</p>
                    <FormItem field="newEmail" :validate-trigger="['blur']" :label="t('login.newEmail')" v-if="modalType === 'email'">
                        <Input v-model.trim="formData.newEmail" spellcheck="false" :placeholder="t('holder.enterHolder')" />
                    </FormItem>
                    <FormItem field="password" :validate-trigger="['blur']" :label="t('login.password')" v-if="modalType === 'email'">
                        <InputPassword v-model.trim="formData.password" spellcheck="false" :placeholder="t('holder.enterHolder')" :invisible-button="false" />
                    </FormItem>
                    <FormItem field="oldPassword" :validate-trigger="['blur']" :label="t('login.oldPassword')" v-if="modalType === 'password'">
                        <InputPassword
                            v-model.trim="formData.oldPassword"
                            spellcheck="false"
                            :placeholder="t('holder.enterHolder')"
                            :invisible-button="false" />
                    </FormItem>
                    <FormItem field="newpassword" :validate-trigger="['blur']" :label="t('login.newPassword')" v-if="modalType === 'password'">
                        <InputPassword
                            v-model.trim="formData.newpassword"
                            spellcheck="false"
                            :placeholder="t('holder.regPasswordHolder')"
                            :invisible-button="false" />
                    </FormItem>
                    <FormItem field="confirmPsw" :validate-trigger="['blur']" :label="t('login.confirmPsw')" v-if="modalType === 'password'">
                        <InputPassword
                            v-model.trim="formData.confirmPsw"
                            spellcheck="false"
                            :placeholder="t('holder.regPasswordHolder')"
                            :invisible-button="false" />
                    </FormItem>
                </Form>
            </div>
            <template #footer>
                <Button class="btn-fill" type="primary" html-type="submit" @click="handleSubmit" :loading="loading">
                    {{ t('ok') }}
                </Button>
            </template>
        </Modal>
    </div>
</template>
<script>
import { defineComponent, reactive, inject, ref } from 'vue'
import { Form } from '@arco-design/web-vue'
import CardBox from '@/components/CardBox.vue'
import { Role } from '@/store'
import { useStore } from 'vuex'
import * as reg from '@/utils/formReg'
import Sha256 from 'crypto-js/sha256'
export default defineComponent({
    components: {
        CardBox
    },
    setup() {
        const t = inject('$t')
        const http = inject('$http')
        const msg = inject('$message')
        const formDom = ref(null || Form)
        const store = useStore()
        const currentTab = ref(0)
        const loading = ref(false)
        const modal = inject('$modal')
        const modalType = ref('phone')
        const modalVisible = ref(false)
        const userData = ref(null)
        let tab = ref([])

        const formData = reactive({
            key: '',
            newEmail: '',
            phone: '',
            password: '',
            oldPassword: '',
            newpassword: '',
            confirmPsw: ''
        })
        const formRule = {
            newEmail: [
                { required: true, message: t('tip.emptyTip', { key: t('login.newEmail') }) },
                { match: reg.emailReg, message: t('tip.emailRegTip1') },
                { maxLength: 50, message: t('tip.emailRegTip') }
            ],
            password: [{ required: true, message: t('tip.emptyTip', { key: t('login.password') }) }],
            oldPassword: [{ required: true, message: t('tip.emptyTip', { key: t('login.oldPassword') }) }],
            newpassword: [
                { required: true, message: t('tip.emptyTip', { key: t('login.newPassword') }) },
                { maxLength: 20, minLength: 6, message: t('tip.pswTip') },
                { match: reg.passwordReg, message: t('tip.pswRegTip') },
                {
                    validator: (val, callback) => {
                        if (val === formData.oldPassword) {
                            callback(t('tip.pswSameTip'))
                        } else {
                            callback()
                        }
                    }
                }
            ],
            confirmPsw: [
                { required: true, message: t('tip.emptyTip', { key: t('login.confirmPsw') }) },
                { required: true, message: t('tip.confirmPswTip') },
                {
                    validator: (val, callback) => {
                        if (val !== formData.newpassword) {
                            callback(t('tip.pswDifferentTip'))
                        } else {
                            callback()
                        }
                    }
                }
            ],
            phone: [{ maxLength: 20, minLength: 0, message: t('tip.phoneTip') }]
        }
        http.account.getUserInfo().then(({ data }) => {
            userData.value = data
            tab.value = [
                {
                    tabName: 'user.basicInfo',
                    tabData: [
                        { icon: 'user', title: 'user.name', msg: data.username, show: true },
                        { icon: 'email', title: 'user.email', msg: data.email, show: true },
                        {
                            icon: 'phone',
                            title: 'user.phone',
                            msg: data.phone || '-',
                            btn: 'modify',
                            type: 'phone',
                            btnFunc: func,
                            show: !store.getters.isRoleType(Role.Admin)
                        }
                    ]
                },
                {
                    tabName: 'user.accountSecurity',
                    tabData: [
                        {
                            icon: 'password',
                            title: 'user.loginPassword',
                            msg: t('tip.changePasswordTip'),
                            btn: 'modify',
                            type: 'password',
                            btnFunc: func,
                            show: data.from !== 'info'
                        },
                        {
                            icon: 'email',
                            title: 'user.bindEmail',
                            msg: t('tip.changeEmailTip'),
                            btn: 'modify',
                            type: 'email',
                            btnFunc: func,
                            show: data.from !== 'info'
                        },
                        {
                            icon: 'key1',
                            title: 'user.sshkey',
                            msg: t('tip.copySSHkeyTip'),
                            btn: 'copy',
                            type: 'copy',
                            btnFunc: func,
                            show: !store.getters.isRoleType(Role.Admin)
                        },
                        {
                            icon: 'a-logoff',
                            title: 'user.destoryAccount',
                            msg: t('tip.destoryAccountTip'),
                            btn: 'destory',
                            type: 'destory',
                            btnFunc: func,
                            show: !store.getters.isRoleType(Role.Admin)
                        }
                    ]
                }
            ]
        })
        if (!store.getters.isRoleType(Role.Admin)) {
            http.project.getUserSSHkey().then(({ data }) => {
                formData.key = data.publicKey
            })
        }
        function func(type) {
            if (type === 'copy') {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const clipboard = require('@/utils/clipboard').clipboard
                clipboard(formData.key || '')
                msg.clear('top')
                msg.info(t('copied'))
            } else if (type === 'destory') {
                modal.warning({
                    hideCancel: false,
                    maskClosable: false,
                    title: t('prompt'),
                    okText: t('ok'),
                    cancelText: t('cancel'),
                    content: t('tip.confirmDestoryAccountTip'),
                    onOk: () => {
                        http.account.destoryAccount(userData.value.id).then(() => {
                            msg.info(t('tip.destoryAccountEmailTip'))
                        })
                    }
                })
            } else {
                modalType.value = type
                modalVisible.value = true
            }
        }
        const handleClose = () => {
            loading.value = false
            formDom.value.resetFields()
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    loading.value = true
                    const params = {}
                    if (modalType.value === 'phone') {
                        // 保存到store
                        http.account
                            .setUserInfo({ phone: formData.phone })
                            .then(({ data }) => {
                                loading.value = false
                                tab.value[0].tabData[2].msg = data.phone
                                modalVisible.value = false
                                msg.info(t('modifySucc'))
                            })
                            .catch(() => {
                                loading.value = false
                            })
                    } else if (modalType.value === 'email') {
                        // 发送邮件
                        params.email = formData.newEmail
                        params.password = Sha256(formData.password).toString()
                        http.account
                            .changeEmail(params)
                            .then(() => {
                                loading.value = false
                                modalVisible.value = false
                                msg.info(t('tip.changeEmailSuccessTip', { email: t('login.newEmail') }))
                            })
                            .catch(() => {
                                loading.value = false
                            })
                    } else if (modalType.value === 'password') {
                        // 更新密码
                        params.oldPassword = Sha256(formData.oldPassword).toString()
                        params.newPassword = Sha256(formData.newpassword).toString()
                        http.account
                            .modifyPassword(params)
                            .then(() => {
                                loading.value = false
                                modalVisible.value = false
                                msg.info(t('modifySucc'))
                            })
                            .catch(() => {
                                loading.value = false
                            })
                    }
                }
            })
        }
        return {
            currentTab,
            formData,
            formDom,
            formRule,
            t,
            tab,
            loading,
            modalType,
            modalVisible,
            handleSubmit,
            Role,
            handleClose
        }
    }
})
</script>
<style lang="less" scoped>
.contain-box {
    color: #666;
    .info-box {
        padding: 30px 60px 80px;
        line-height: 30px;
        .flex-box {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #e0e0e0;
            padding: 10px;
        }
        .flex-box .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 50px;
            margin-right: 16px;
            padding-bottom: 8px;
        }
        .flex-box .operate {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 150px;
        }
        .flex-box .content {
            flex: 1;
            font-size: 14px;
            line-height: 24px;
            color: #666;
            p {
                font-size: 16px;
                font-weight: bold;
                color: #333;
            }
        }
    }
}
</style>
<style>
.modal-title {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
}
</style>
